import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';
import { fullOffersFields } from './offers';

export const GET_PAYABLES = gql`
  query payables($filterClosedBills: Boolean = true $withAppointments: Boolean = false) {
    payables(filterClosedBills: $filterClosedBills) ${CLIENT} {
      hasGrouping
      filterZeroDollarPayables
      affiliatePayables {
        providerName
        providerInternalName
        standingAmount
        url
        token
      }
      payables {
        accountNumber
        id
        accountId
        standingAmount
        offer
        aNextPayment
        bills {
          id
          aPendingInsurance
          admissionDate
          amountDue
          displayName
          isBeforeCollection
          status
          isBillPastDue
          rawVisitType
          aPromptDiscount
          aReliefDiscount
          aPromptPayDiscount
          selfPay
          eligiblePromptDiscount
          dunningCode
          claimNo
          paymentPlanExists
          partnerName
          adjustments {
            kind
          }
          extraFields {
            key
            value
          }
        }
        appointments @include(if: $withAppointments) {
          id
          visitId
          providerName
          amountPaid
          nextPayment
          remainingBalance
          originalBalance
          cptCodes
          hasPaymentPlan
          appearances {
            id
            status
            appearanceDate
            active
          }
          patientName
        }
        paymentPlan {
          id
          balanceRemaining
          planType
          expectedAmount
          standingAmount
          amountOutOfPlan
          status
          nextStandingAmount
          missedInstallmentsCount
          missedInstallmentsAmount
          startDate
          lastInstallmentDate
          leftInstallmentsCount
          installmentsNumber
          remainingInstallmentsTotal
          amountPerInstallment
          lastInstallmentAmount
          totalPaidBalance
          payableId
          fundedBy
          genericIdentifier
          nextInstallment {
            installmentDate
            amountToPay
            serviceFee
            amountToPayWithFee
          }
          paymentMethod {
            id
            expiredAt
            updatedAt
          }
          billIds
          claimNumbers
          serviceFeeAmount
        }
        assistMaxPpSetting
        assistOffersByScore {
          patientScore
          defaultOption
          firstOffer
          secondOffer
          thirdOffer
          otherOptionsPatient
        }
        limits {
          maxPaymentsNumber
          minStandingAmount
          lastPaymentMinAmount
          minPaymentAmount
        }
        payee {
          companyName
          companyDisplayName
          paypage
          paypageId
          creditCardProcessor
          vaultUrl
          processorsGenericFields
          echeckPermissions
          creditCardPermissions
          paypalPermissions
          paypalClientToken
          paymentApplicationStrategy
          provider {
            id
            contactLink
            email
            hoursOfOperation
            internalName
            name
            phone
            state
            tollFreePhone
            zipcode
            facilityName
          }
        }
        guarantor {
          score {
            capacity
            segmentCode
          }
          externalId
        }
        scheduledPayments {
          id
          amount
          userPayment
        }
        discount {
          uniqueIdentifier
          expirationDate
        }
        oneClickPaymentMethod {
          amount
          creditCard {
            id
            last4
          }
        }
        ${fullOffersFields}
      }
    }
  }
`;

export const getSingleBillQuery = isChargeDetailsEnabled => {
  return gql`
  query getSingleBill($id: ID!) {
    bill(id: $id) ${CLIENT} {
      visitNumber
      accountNumber
      aPendingInsurance
      aInsuranceResp
      aPositiveCollectionPlacement
      aNegativeCollectionPlacement
      aPayerDiscount
      aPatientPayments
      insuranceAmount
      aTotalBilled
      aReliefDiscount
      aPromptPayDiscount
      planCoverages {
        payer
        link
        phone
        priority
        insuranceName
        insuranceId
        insurancePlan
      }
      charges {
        amount
        cptCode {
          code
          translation
        }
        date
        description
        #quantity
        revenueCode {
          code
          translation
        }
      }
      ${
        isChargeDetailsEnabled
          ? `
      chargesDetails {
        description
        aTotalBilledAmount
        patientPortionAmount
        insurancePaymentAmount
        patientPaymentAmount
        adjustmentsAmount
      }`
          : ''
      }  
    }
  }
`;
};

export const GET_PAYABLE_STANDING_AMOUNT = gql`
  query payableStandingAmount {
    payableStandingAmount ${CLIENT}
  }
`;
