import { types } from 'mobx-state-tree';
import { camelCase } from 'lodash';

const CptCode = types.model('ChargesCptCode', {
  code: types.maybeNull(types.number),
  translation: types.maybeNull(types.string)
});

const RevenueCode = types.model('ChargesRevenueCode', {
  code: types.maybeNull(types.number),
  translation: types.maybeNull(types.string)
});

const Charge = types.model('SingleBillCharge', {
  amount: types.number,
  category: types.maybeNull(types.string),
  cptCode: types.maybeNull(CptCode),
  date: types.string,
  description: types.maybeNull(types.string),
  revenueCode: types.maybeNull(RevenueCode)
});

const BillPlanCoverage = types.model('BillPlanCoverage', {
  payer: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  priority: types.maybeNull(types.number),
  insuranceName: types.maybeNull(types.string),
  insuranceId: types.maybeNull(types.string),
  insurancePlan: types.maybeNull(types.string)
});

const ExtraField = types.model('ExtraField', {
  key: types.maybeNull(types.string),
  value: types.maybeNull(types.string)
});

const Adjustment = types.model('SingleBillAdjustment', {
  kind: types.maybeNull(types.number)
});

const ChargeDetail = types.model('ChargeDetail', {
  description: types.maybeNull(types.string),
  aTotalBilledAmount: types.maybeNull(types.integer),
  patientPortionAmount: types.maybeNull(types.integer),
  insurancePaymentAmount: types.maybeNull(types.integer),
  patientPaymentAmount: types.maybeNull(types.integer),
  adjustmentsAmount: types.maybeNull(types.integer)
});

export const Bill = types
  .model('Bill', {
    //single bill box params
    id: types.string,
    displayName: types.string,
    admissionDate: types.string,
    rawVisitType: types.maybeNull(types.string),
    partnerName: types.maybeNull(types.string),
    isBeforeCollection: types.maybeNull(types.boolean),
    status: types.maybeNull(types.string),
    isBillPastDue: types.maybeNull(types.boolean),
    paymentPlanExists: types.maybeNull(types.boolean),
    amountDue: types.number,
    aPromptDiscount: types.maybeNull(types.number),
    aReliefDiscount: types.maybeNull(types.number),
    aPromptPayDiscount: types.maybeNull(types.number),
    eligiblePromptDiscount: types.maybeNull(types.number),
    //Added custom props
    amountRank: types.optional(types.number, 0),
    dateRank: types.optional(types.number, 0),
    selectable: types.optional(types.boolean, false),
    isSelected: types.optional(types.boolean, false),
    hasPromptDiscount: types.optional(types.boolean, false),
    dunningCode: types.maybeNull(types.string),
    selfPay: types.maybeNull(types.boolean),
    claimNo: types.maybeNull(types.string),
    //Beautiful Bill extra params
    visitNumber: types.maybeNull(types.string),
    accountNumber: types.optional(types.string, '0'),
    charges: types.optional(types.array(Charge), []),
    planCoverages: types.optional(types.array(BillPlanCoverage), []),
    adjustments: types.optional(types.array(Adjustment), []),
    aTotalBilled: types.optional(types.number, 0),
    insuranceAmount: types.optional(types.number, 0),
    aPendingInsurance: types.maybeNull(types.number),
    aPayerDiscount: types.optional(types.number, 0),
    aInsuranceResp: types.optional(types.number, 0),
    aPositiveCollectionPlacement: types.optional(types.number, 0),
    aNegativeCollectionPlacement: types.optional(types.number, 0),
    //aMemberResponsibility: types.optional(types.number, 0),
    aPatientPayments: types.optional(types.number, 0),

    extraFields: types.optional(types.array(ExtraField), []),
    //does bill data contain all Beautiful Bill params?
    isDataComplete: types.optional(types.boolean, false),
    chargesDetails: types.optional(types.array(ChargeDetail), []),
    type: 'bill'
  })
  .views(self => ({
    groupedCharges(showSoloItemInGroupedCharges) {
      /**
       If charges have the same Revenue Code, they will be grouped. The surcharges will be defined as follows:
       - The subcharges will first try to use "CPT Code Translation"
       - If not available, try to use "Charge Description"
       - If not available, try to use "Revenue Code Translation"
       - If not available, use "Medical Service"

       If a charge does not have a revenue code, it will have its own line item and not be grouped.
       - The charge will use CPT Code Translation, if available.
       - If not available, will use Charge Description
       - If not available, will use "Medical Serivce"
       */

      const defaultChargeTitle = 'Medical Service';
      const singleCharges = [],
        groupedCharges = {};

      const createNewGroup = (charge, groupKey) => {
        if (!groupedCharges[groupKey]) {
          //create new group entry
          groupedCharges[groupKey] = {
            title: groupKey,
            items: [],
            amount: 0
          };
        }
      };

      const addToGroup = (charge, groupKey) => {
        const { amount, description, cptCode } = charge;
        const chargeDescription =
          cptCode?.translation || description || groupKey;
        if (groupedCharges[groupKey]) {
          groupedCharges[groupKey].items.push({
            description: chargeDescription,
            amount
          });
          groupedCharges[groupKey].amount += amount;
        }
      };

      self.charges.forEach(charge => {
        const revCodeTranslation = charge.revenueCode?.translation || null;
        if (revCodeTranslation) {
          createNewGroup(charge, revCodeTranslation);
          addToGroup(charge, revCodeTranslation);
        } else {
          singleCharges.push({
            title:
              charge.cptCode?.translation ||
              charge.description ||
              defaultChargeTitle,
            amount: charge.amount
          });
        }
      });

      const retArr = Object.keys(groupedCharges).map(key => {
        const groupedCharge = groupedCharges[key];
        if (groupedCharge.items.length < 2 && !showSoloItemInGroupedCharges) {
          //Remove sub-items if only single item is present
          groupedCharge.items.pop();
        }

        // if all items are the same as the title, remove the items.
        const sameChargesDescription = groupedCharge.items.every(
          subCharge => groupedCharge.title === subCharge.description
        );

        if (sameChargesDescription) {
          groupedCharge.items = [];
        }

        return groupedCharge;
      });

      return retArr.concat(singleCharges);
    },
    get totalDiscounts() {
      return self.aPromptDiscount + self.aReliefDiscount;
    },

    getInsurances(nameConfig) {
      if (!nameConfig || self.planCoverages.length === 0)
        return {
          primaryInsurance: null,
          secondaryInsurance: null,
          tertiaryInsurance: null
        };

      const insuranceField = camelCase(nameConfig.insurance_field);

      const sortedPlanCoverages = self.planCoverages
        .slice()
        .sort((a, b) => a.priority - b.priority);

      const insurances = sortedPlanCoverages.map(
        planCoverage => planCoverage?.[insuranceField]
      );

      return {
        primaryInsurance: insurances[0],
        secondaryInsurance: insurances[1],
        tertiaryInsurance: insurances[2]
      };
    }
  }))
  .actions(self => ({
    setSelected: val => {
      self.isSelected = val;
    }
  }));
